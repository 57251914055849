import PropTypes from 'prop-types';
import React from 'react';

import { clamp } from '@utils/math';

import { Progress, Wrapper } from './ProgressBar.styled';

export function ProgressBar({ progress, color, backgroundColor }) {
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Progress color={color} progress={clamp(0, progress, 100)} />
    </Wrapper>
  );
}

ProgressBar.defaultProps = {
  backgroundColor: undefined,
};

ProgressBar.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
