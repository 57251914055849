import { theme } from '@themes/main';

import { getThemeColor } from '@utils/styled';

import { VOUCHER_TYPE } from '@common/constants/vip';

const orange = getThemeColor('hue.orange')({ theme });
const red = getThemeColor('hue.red')({ theme });
const green = getThemeColor('hue.green')({ theme });

export const getProgressColor = (type, progress) => {
  if (type === VOUCHER_TYPE.BON) {
    if (progress >= 14) return green;

    if (progress >= 7) return orange;

    return red;
  }

  if (progress >= 50) return green;

  if (progress > 20) return orange;

  return red;
};
