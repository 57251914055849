export const VOUCHER_EXPIRED = 'expired';
export const VOUCHER_REDEEMED = 'redeemed';

export const GOODIE_EXPIRED = 'expired';
export const GOODIE_REDEEMED = 'redeemed';

export const VOUCHER_TYPE = {
  GIFT: 'GiftVoucher',
  PROMOTION: 'PromotionVoucher',
  BON: 'BonVoucher',
};
