import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { BARCODE_HEIGHT } from '@routes/VIPHome/VIPHome.styled';

import useIsomorphicLayoutEffect from '@common/hooks/useIsomorphicLayoutEffect';

import {
  FlipCardBack,
  FlipCardContainer,
  FlipCardFront,
} from './FlipCard.styled';

function calculateStartPosition(vipCardEl, cb) {
  if (!vipCardEl) {
    return;
  }

  const VIPCardTopPosition = vipCardEl.getBoundingClientRect().top;
  const viewportCenter = document.documentElement.clientHeight / 2;
  const barcodeStartPosition =
    -viewportCenter + VIPCardTopPosition + BARCODE_HEIGHT / 2;

  cb(barcodeStartPosition);
}

const FlipCard = ({
  isOpen,
  cardRef,
  shouldSlideIn,
  bottomText,
  imageFront,
  imageBack,
}) => {
  const [startPosition, setStartPosition] = useState(0);

  useIsomorphicLayoutEffect(() => {
    calculateStartPosition(cardRef?.current, setStartPosition);
  }, [isOpen]);

  return (
    <Fragment>
      <FlipCardContainer>
        <FlipCardFront
          withFadeIn={!cardRef}
          startTop={`${startPosition / 10}rem`}
          shouldSlideIn={shouldSlideIn}
        >
          {imageFront}
        </FlipCardFront>
        <FlipCardBack
          withFadeIn={!cardRef}
          startTop={`${startPosition / 10}rem`}
          shouldSlideIn={shouldSlideIn}
        >
          {imageBack}
        </FlipCardBack>
      </FlipCardContainer>
      {bottomText}
    </Fragment>
  );
};

FlipCard.propTypes = {
  isOpen: PropTypes.bool,
  cardRef: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.any,
  }),
  shouldSlideIn: PropTypes.bool,
  bottomText: PropTypes.node,
  imageFront: PropTypes.node,
  imageBack: PropTypes.node,
};

FlipCard.defaultProps = {
  isOpen: false,
  cardRef: undefined,
  shouldSlideIn: false,
  bottomText: null,
  imageFront: null,
  imageBack: null,
};

export default FlipCard;
