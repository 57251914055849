import { useEffect, useState } from 'react';

export const useMediaQuery = query => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    setMatches(mediaQuery.matches);
    const handler = event => setMatches(event.matches);

    // support legacy safari browsers

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handler);
    } else {
      mediaQuery.addListener(handler);
    }

    return () => mediaQuery.removeEventListener('change', handler);
  }, [query]);

  return matches;
};
