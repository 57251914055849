import styled, { css } from 'styled-components';

import { getThemeColor, getThemeTransition, media } from '@utils/styled';

import Typography from '@common/components/Typography';

const indicatorCommonStyled = css`
  content: '';
  position: absolute;
  top: 0;
  z-index: 2;
  width: 1px;
  height: ${({ slidesHeight }) => slidesHeight || 0}px;
  background-color: ${getThemeColor('bluegrey.80')};
`;

export const CarouselWrapper = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'widthBased', 2)};

  .swiper-horizontal {
    ${({ withMobileOverflow }) =>
      withMobileOverflow &&
      css`
        ${media.from880down`
          overflow: visible;
      `}
      `}
    ${({ withDesktopOverflow }) =>
      withDesktopOverflow &&
      css`
        ${media.from880up`
          overflow: visible;
      `}
      `}

    ${({ canMoveLeft }) =>
      canMoveLeft &&
      css`
        ${media.from880up`
        &:before {
          ${indicatorCommonStyled}
          left: 0;
        }
      `}
      `}

    ${({ canMoveRight }) =>
      canMoveRight &&
      css`
        ${media.from880up`
        &:after {
          ${indicatorCommonStyled}
          right: 0;
        }
      `}
      `}
  }

  .swiper {
    cursor: grab;

    &-grabbing {
      cursor: grabbing;

      * {
        cursor: grabbing;
      }
    }
  }

  .swiper-wrapper {
    position: relative;
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
    box-sizing: border-box;
    font-size: 0;
    cursor: ${({ slideCursor }) => slideCursor || 'grab'};
  }

  .swiper-scrollbar {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 4)}
    background-color: ${getThemeColor('bluegrey.90')};
    cursor: pointer;

    & .swiper-scrollbar-drag {
      height: 100%;
      background-color: ${getThemeColor('bluegrey.80')};
      cursor: grab;
    }
  }

  .swiper-pagination-current {
    ${({ theme }) => theme.getFluidSpacing('margin-right', 'equal', 6)};
  }

  .swiper-pagination-total {
    ${({ theme }) => theme.getFluidSpacing('margin-left', 'equal', 6)};
  }
`;

export const ButtonLeft = styled.button`
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;

  svg {
    display: block;
    fill: ${getThemeColor('bluegrey.60')};
    transition: ${getThemeTransition('fill')};
  }

  &:hover,
  &:focus {
    outline: none;

    > svg {
      fill: ${getThemeColor('bluegrey.40')};
    }
  }

  &:disabled {
    cursor: default;

    > svg {
      fill: ${getThemeColor('bluegrey.80')};
    }
  }
`;

export const ButtonRight = styled(ButtonLeft)`
  /* stylelint-disable-next-line no-descending-specificity */
  > svg {
    transform: rotate(180deg);
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 4)}

  ${media.from640up`
    margin-right: 0;
    margin-left: 0;
    `}
`;

export const PaginationWrapper = styled(Typography).attrs({
  variant: 'paragraph1',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  ${media.from1200up`
    font-size: 2.2rem;
  `}
`;
