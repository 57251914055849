import styled from 'styled-components';

import { getThemeTransition } from '@utils/styled';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 0.5rem;
  max-height: 0.5rem;
  border-radius: ${({ theme }) => theme.borderRadiuses.pill}rem;
  background-color: ${({
    backgroundColor,
    theme: {
      colors: { bluegrey },
    },
  }) => backgroundColor || bluegrey[90]};
`;

export const Progress = styled.div`
  position: absolute;
  right: 0;
  width: ${({ progress }) => progress}%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadiuses.pill}rem;
  background-color: ${({ color }) => color};
  transition: ${getThemeTransition('all')};
`;
