import styled, { css, keyframes } from 'styled-components';

import { getThemeTransition } from '@utils/styled';

const flipFront = ({ startTop }) => keyframes`
  0% {
    transform: translateY(${startTop}) rotateY(0);
  }

  100% {
    transform: translateY(0) rotateY(-180deg);
  }
`;

const flipBack = ({ startTop }) => keyframes`
  0% {
    transform: translateY(${startTop}) rotateY(-180deg);
  }

  100% {
    transform: translateY(0) rotateY(0);
  }
`;

const flipCardStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backface-visibility: hidden;
  display: flex;
  width: 100%;
  height: 100%;
  transform-origin: center center;
`;

export const FlipCardContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 64.5%;
`;

export const FlipCardFront = styled.div`
  ${flipCardStyles};
  transform: ${({ shouldSlideIn, startTop }) =>
    `translateY(${startTop}) rotateY(${shouldSlideIn ? -180 : 0}deg)`};

  .modal-transition-enter-active &,
  .modal-transition-enter-done & {
    animation: ${flipFront} forwards 700ms ease;
    animation-delay: ${({ shouldSlideIn }) => `${shouldSlideIn ? 0 : 500}ms`};
  }

  .modal-transition-exit-active &,
  .modal-transition-exit-done & {
    animation: ${flipFront} reverse 700ms ease;
  }
`;

export const FlipCardBack = styled.div`
  ${flipCardStyles};
  transform: ${({ shouldSlideIn, startTop }) =>
    `translateY(${startTop}) rotateY(${shouldSlideIn ? 0 : -180}deg)`};

  .modal-transition-enter-active &,
  .modal-transition-enter-done & {
    animation: ${flipBack} forwards
      ${getThemeTransition('', 'verySlow', 'default')};
    animation-delay: ${({ shouldSlideIn }) => `${shouldSlideIn ? 0 : 500}ms`};
  }

  .modal-transition-exit-active &,
  .modal-transition-exit-done & {
    animation: ${flipBack} reverse
      ${getThemeTransition('', 'verySlow', 'default')};
  }
`;
