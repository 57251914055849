import styled from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 5)};
`;

export const CardTitleImage = styled.div`
  position: relative;
  overflow: hidden;
  align-self: flex-start;
  min-width: 7.5rem;
  height: 7.5rem;
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'equal', 3)};
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CardTitleMainHeading = styled(Typography).attrs({
  variant: 'h6',
  customAs: 'p',
})`
  margin-bottom: 0;
  color: ${getThemeColor('bluegrey.100')};
  font-weight: 700;
`;

export const CardTitleHeading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: span 9;
`;

export const CardTitleSubheading = styled(Typography).attrs({
  variant: 'body2',
})`
  margin-bottom: 0;
  color: ${getThemeColor('bluegrey.60')};
  font-weight: 500;
`;

export const InfoTextWrapper = styled.div`
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 5)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 5)};
`;

export const InfoText = styled(Typography).attrs({
  variant: 'body2',
})`
  color: ${getThemeColor('bluegrey.60')};
`;

export const CardDetailsContainer = styled.div`
  overflow: auto;
  display: flex;
  height: 100%;
  ${({ theme }) => theme.getFluidSpacing('margin-left', 'scale', 6, true)};
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'scale', 6, true)};
`;

export const CardDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'scale', 6)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'scale', 6)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 4)};

  @media screen and (orientation: landscape) {
    justify-content: flex-start;
  }

  ${media.from480up`
    justify-content: flex-start;
    padding-bottom: 0;
  `}
`;
