import styled from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid ${getThemeColor('bluegrey.90')};
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  background: white;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 7)};
`;

export const LogoImageWrapper = styled.div`
  margin-bottom: 10%;
  padding: 0 8%;
`;

export const LogoImage = styled.img`
  width: 33%;
`;

export const BarcodeWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
`;

export const BarcodeImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;
`;

export const VIPIconWrapper = styled.div`
  position: absolute;
  top: 20%;
  right: 8%;
  z-index: 1;
  width: 60%;
  transform: translateY(-50%);

  & > svg {
    fill: #f6f6f6;
    width: 100%;
  }
`;

export const BarcodeImage = styled.img`
  position: absolute;
  z-index: 2;
  width: 100%;
  mix-blend-mode: multiply;
`;

export const BarcodeNumber = styled(Typography).attrs({
  variant: 'paragraph1',
})`
  font-weight: 400;
  font-size: 4vw;
  text-align: center;
  letter-spacing: 0;

  ${media.from480up`
    font-size: 2rem;
  `}
`;
