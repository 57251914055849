import React, { createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

export const CarouselContext = createContext({
  carousel: null,
  setCarousel: () => {},
  id: null,
});

export const useCarouselContext = () => useContext(CarouselContext);

/**
 * Currently, this context is used to pass the carousel instance to the ZoomImageModal component.
 * Does not allow for multiple carousels on the same page.
 * TODO - refactor to allow for multiple carousels on the same page.
 */
export const CarouselContextProvider = ({ children }) => {
  const carouselRef = useRef(null);

  return (
    <CarouselContext.Provider
      value={{
        carousel: carouselRef.current,
        setCarousel: carousel => {
          carouselRef.current = carousel;
        },
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
};

CarouselContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
