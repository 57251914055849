import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import debounce from 'lodash/debounce';
import {
  Scrollbar,
  Mousewheel,
  Navigation,
  Pagination,
  FreeMode,
  Keyboard,
} from 'swiper';

import 'swiper/swiper-bundle.min.css';

import { breakpointQuery } from '@utils/styled';

import { Chevron } from '@common/components/Icons';
import { useMediaQuery } from '@common/hooks/useMediaQuery';
import { useCarouselContext } from '@common/contexts/carouselContext';

import {
  CarouselWrapper,
  ButtonLeft,
  ButtonRight,
  Controls,
  PaginationWrapper,
} from './Carousel.styled';

const defaultBreakpointConfig = {
  scrollbar: {
    enabled: true,
    hide: false,
    draggable: true,
  },
  navigation: {
    enabled: true,
  },
};

const Carousel = ({
  children,
  className,
  showBorderIndicators,
  breakpoints = {},
  infinite,
  hideNav,
  hideScrollbar,
  withCounter,
  setOutterSwiper,
  onIndexChange,
  withMobileOverflow,
  withDesktopOverflow,
  slideCursor,
  isFreeModeDisabled,
}) => {
  const swiperRef = useRef(null);
  const carouselWrapperRef = useRef(null);
  const [swiperProgress, setSwiperProgress] = useState({});
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const paginationRef = useRef(null);
  const scrollbarContainerRef = useRef(null);
  const isFrom800down = useMediaQuery(breakpointQuery.from800down);
  const breakpointsConfig = useMemo(
    () =>
      Object.keys(breakpoints).reduce(
        (config, key) => ({
          ...config,
          [key]: {
            ...breakpoints[key],
            scrollbar: {
              ...(!hideScrollbar && defaultBreakpointConfig.scrollbar),
            },
          },
        }),
        {},
      ),
    [breakpoints, hideScrollbar],
  );
  const carouselContext = useCarouselContext();

  const modules = useMemo(() => {
    const dynamicModules = [Keyboard, FreeMode, Pagination];

    if (!hideNav) {
      dynamicModules.push(Navigation);
    }

    if (!hideScrollbar) {
      dynamicModules.unshift(Scrollbar);
    }

    if (!isFrom800down) {
      dynamicModules.unshift(Mousewheel);
    }

    if (withCounter) {
      dynamicModules.push(Pagination);
    }

    return dynamicModules;
  }, [hideNav, hideScrollbar, isFrom800down, withCounter]);

  const hasSlides = swiperRef.current?.swiper.slides.length > 1;
  const slidesHeight = swiperRef.current?.swiper.slides?.[0]?.offsetHeight;

  const onNavClick = direction => {
    const currentIndex = swiperRef.current.swiper.activeIndex;
    const { slidesPerView } = swiperRef.current.swiper.params;
    const numberOfSlides = swiperRef.current.swiper.slides.length;
    let validIndex;
    let nextIndex;

    if (direction === 'prev') {
      validIndex = currentIndex - Math.trunc(slidesPerView || 1);
      nextIndex = validIndex ? validIndex + 1 : 0;
    }

    if (direction === 'next') {
      validIndex = currentIndex + Math.trunc(slidesPerView || 1);
      nextIndex =
        validIndex < numberOfSlides ? validIndex - 1 : numberOfSlides - 1;
    }

    swiperRef.current.swiper.slideTo(nextIndex, 500);
  };

  const onSwiperProgress = ({ progress, isEnd, isBeginning }) => {
    setSwiperProgress({
      progress,
      isEnd,
      isBeginning,
    });
  };

  const onMouseWheelEnds = debounce(onSwiperProgress, 100);

  /**
   * this prevents multiple carousels in a viewport to be swiped at the same time
   * only the one that is hovered will be swiped
   */
  useEffect(() => {
    const mouseInteractionRef = carouselWrapperRef.current;
    const onMouseDown = () => {
      swiperRef.current.swiper.keyboard.enable();
    };

    const onMouseUp = () => {
      swiperRef.current.swiper.keyboard.disable();
    };

    if (mouseInteractionRef && swiperRef?.current?.swiper) {
      mouseInteractionRef.addEventListener('mouseenter', onMouseDown);
      mouseInteractionRef.addEventListener('mouseleave', onMouseUp);
    }

    return () => {
      mouseInteractionRef.removeEventListener('mouseenter', onMouseDown);
      mouseInteractionRef.removeEventListener('mouseleave', onMouseUp);
    };
  }, [swiperRef]);

  return (
    <CarouselWrapper
      className={className}
      slidesHeight={slidesHeight}
      canMoveRight={
        showBorderIndicators && swiperProgress.progress < 1 && hasSlides
      }
      canMoveLeft={
        showBorderIndicators && swiperProgress.progress > 0 && hasSlides
      }
      withBorders={!!showBorderIndicators}
      withMobileOverflow={withMobileOverflow}
      withDesktopOverflow={withDesktopOverflow}
      isScrollbarHidden={hideScrollbar}
      slideCursor={slideCursor}
      ref={carouselWrapperRef}
    >
      <Swiper
        onBeforeInit={swiper => {
          swiperRef.current = { swiper };
        }}
        onSwiper={swiper => {
          setOutterSwiper?.(swiper);
          setSwiperProgress({
            progress: swiper.progress,
            isEnd: swiper.isEnd,
            isBeginning: swiper.isBeginning,
          });
          carouselContext.setCarousel(swiper);
        }}
        onRealIndexChange={swiper => onIndexChange?.(swiper.realIndex)}
        modules={modules}
        breakpoints={breakpointsConfig}
        watchSlidesProgress
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
        }}
        pagination={{
          type: 'fraction',
          el: paginationRef.current,
        }}
        freeMode={{
          enabled: !isFreeModeDisabled,
          sticky: true,
          momentumBounce: false,
        }}
        loop={infinite}
        scrollbar={
          !hideScrollbar && {
            ...defaultBreakpointConfig.scrollbar,
            el: scrollbarContainerRef.current,
          }
        }
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        onScrollbarDragStart={swiper => {
          const dragElRef = swiper.scrollbar.dragEl;

          dragElRef.style.cursor = 'grabbing';
        }}
        onScrollbarDragEnd={swiper => {
          onSwiperProgress(swiper);
          const dragElRef = swiper.scrollbar.dragEl;

          dragElRef.style.cursor = 'grab';
        }}
        touchEventsTarget="container"
        onTouchStart={swiper => swiper.el.classList.add('swiper-grabbing')}
        onTouchEnd={swiper => swiper.el.classList.remove('swiper-grabbing')}
        onTransitionEnd={swiper => onSwiperProgress(swiper)}
        onScroll={swiper => onMouseWheelEnds(swiper)}
      >
        {React.Children.map(children, child => (
          <SwiperSlide>{React.cloneElement(child)}</SwiperSlide>
        ))}
      </Swiper>
      {!hideNav && children.length > 1 && (
        <Controls>
          <ButtonLeft ref={prevButtonRef} onClick={() => onNavClick('prev')}>
            <Chevron />
          </ButtonLeft>
          {withCounter && <PaginationWrapper ref={paginationRef} />}
          <ButtonRight ref={nextButtonRef} onClick={() => onNavClick('next')}>
            <Chevron />
          </ButtonRight>
        </Controls>
      )}
      {!hideScrollbar && (
        <div ref={scrollbarContainerRef} className="swiper-scrollbar" />
      )}
    </CarouselWrapper>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  breakpoints: PropTypes.objectOf(
    PropTypes.shape({
      slidesPerView: PropTypes.number,
      spaceBetween: PropTypes.number,
    }),
  ),
  className: PropTypes.string,
  showBorderIndicators: PropTypes.bool,
  infinite: PropTypes.bool,
  hideNav: PropTypes.bool,
  hideScrollbar: PropTypes.bool,
  withCounter: PropTypes.bool,
  withMobileOverflow: PropTypes.bool,
  withDesktopOverflow: PropTypes.bool,
  setOutterSwiper: PropTypes.func,
  onIndexChange: PropTypes.func,
  slideCursor: PropTypes.string,
  isFreeModeDisabled: PropTypes.bool,
};

Carousel.defaultProps = {
  className: null,
  showBorderIndicators: false,
  infinite: false,
  hideNav: false,
  hideScrollbar: false,
  withCounter: false,
  withMobileOverflow: false,
  withDesktopOverflow: false,
  breakpoints: {},
  setOutterSwiper: undefined,
  onIndexChange: undefined,
  slideCursor: 'grab',
  isFreeModeDisabled: false,
};

export default Carousel;
